import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import CrmIcon from 'components/CrmIcon'
import useCrmStatus from 'components/CrmStatus'
import Avatar from 'global/Avatar'
import DropDownMenu from 'global/DropDownMenu'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint, Team } from 'utils/types'

import EmptyStates from './EmptyStates'

interface Props {
  team: Team
}

const StyledSharedListCard = styled.div`
  border: ${({ theme }) => theme.border};
  &:hover {
    border-color: ${({ theme }) => theme.colors.purple};
    border-style: solid;
    transition: all 0.2s;
  }
`

const TeamSharedListsWidget: React.FC<Props> = ({ team }) => {
  const { user } = useCurrentUser()
  const { salesforceConnected, hubspotConnected } = useCrmStatus(user.team?.slug)
  const history = useHistory()

  const unshareListMutation = useMutation(
    (listId: string) => callApi(api.unshareCompanyList, listId, team.slug),
    {
      onSuccess: () => {
        getSharedListsQuery.refetch()
      },
    },
  )

  const getSharedListsQuery = useQuery(
    ['getSharedCompanyLists', team.slug],
    () => callApi(api.getSharedCompanyLists, team.slug),
    {
      enabled: !!team.slug,
    },
  )

  const companyLists = getSharedListsQuery.data?.company_lists

  const renderIcon = (list: CompanyListBlueprint) => {
    if (list.dynamic && salesforceConnected) {
      return <CrmIcon crm="salesforce" style={{ fontSize: '18px' }} />
    }
    if (list.dynamic && hubspotConnected) {
      return <CrmIcon crm="hubspot" style={{ fontSize: '18px' }} />
    }
    if (list.list_type === 'personas') {
      return (
        <Typography component="p" fontSize="14" color="fog" lineHeight={1.2}>
          <i className="far fa-users"></i>
        </Typography>
      )
    }
    if (list.list_type === 'jobs') {
      return (
        <Typography component="p" fontSize="14" color="fog" lineHeight={1.2}>
          <i className="far fa-briefcase"></i>
        </Typography>
      )
    }
    if (list.list_category === 'sales') {
      return (
        <Typography component="p" fontSize="14" color="fog" lineHeight={1.2}>
          <i className="far fa-building"></i>
        </Typography>
      )
    }
    return (
      <Typography component="p" fontSize="14" color="fog" lineHeight={1.2}>
        <i className="far fa-list"></i>
      </Typography>
    )
  }

  if (getSharedListsQuery.isLoading) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <>
      <Widget
        title={
          <Typography className="flex items-center" fontWeight={600}>
            Shared Lists
          </Typography>
        }
        cta={<Avatar name={user.team.name} src={user.team?.logo} size="24px" />}
        className="flex flex-col gap-2"
      >
        {companyLists?.map((list) => (
          <div key={list.uuid} className="company-list-item">
            <StyledSharedListCard
              className="flex rounded-xl justify-between items-center py-1 px-2 cursor-pointer"
              onClick={() => history.push(`/${user.team.slug}/lists/${list.slug}`)}
            >
              <div className="flex items-center gap-2">
                {renderIcon(list)}
                <Typography component="p" fontSize="12" lineHeight={1.2} color="fog">
                  {list.name}
                </Typography>
              </div>
              <div className="flex items-center gap-2">
                <Typography component="p" fontSize="12" color="rain" lineHeight={1}>
                  {list.company_list_items_count}
                </Typography>
                <div onClick={(e) => e.stopPropagation()}>
                  <DropDownMenu
                    menuItems={[
                      {
                        label: 'Unshare',
                        onSelect: () => {
                          unshareListMutation.mutate(list.uuid)
                        },
                      },
                    ]}
                    trigger={
                      <Typography color="rain" fontSize="12" className="px-2 -mr-1">
                        <i className="far fa-ellipsis-v" />
                      </Typography>
                    }
                  />
                </div>
              </div>
            </StyledSharedListCard>
          </div>
        ))}

        <EmptyStates companyLists={companyLists} refetch={getSharedListsQuery.refetch} />
      </Widget>
    </>
  )
}

export default TeamSharedListsWidget

import React, { useMemo } from 'react'

import { useAccessControl } from 'global/AccessControl'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import PillV2 from 'ui-components/PillV2'

import { List } from 'utils/types'

interface Props {
  lists: List[]
  filter: string
  onSetFilter: (filter: string) => void
  teamSlug?: string
}

const ListPills: React.FC<Props> = ({ lists, filter, onSetFilter, teamSlug }) => {
  const { canViewPortfolio, canViewOffers } = useAccessControl(teamSlug)
  const { team } = useTeam(teamSlug)

  const uniqueListOptions = useMemo(() => {
    const options: string[] = []

    lists.forEach((list) => {
      options.push(`${list.list_type}-${list.list_category}`)
    })

    const uniqueOptions = new Set(options)
    // don't currently have filters for sales lists or other lists
    return Array.from(uniqueOptions).filter(
      (option) => !option.includes('sales') && !option.includes('other'),
    )
  }, [lists])

  const connectionsListPresent = useMemo(() => {
    return lists.some(
      (list) => list.default_type_identifier && list.default_type_identifier === 'connections',
    )
  }, [lists])

  if (uniqueListOptions.length === 0 && !canViewPortfolio && !canViewOffers) {
    return null
  }

  const getOptionData = (option: string) => {
    // switch statement with option
    switch (option) {
      case 'companies-portfolio':
        return { title: 'Portfolio', icon: 'user' }
      case 'people-talent':
        return { title: 'Talent', icon: 'star' }
      case 'jobs-talent':
        return { title: 'Jobs', icon: 'briefcase' }
      case 'discounts-resources':
        return { title: 'Discounts', icon: 'tag' }
      case 'people-resources':
        return { title: 'Advisors', icon: 'users' }
      default:
        return {
          title: option.split('-')[0].charAt(0).toUpperCase() + option.split('-')[0].slice(1),
          icon: undefined,
        }
    }
  }

  return (
    <div className="mt-10 flex flex-wrap gap-2">
      <PillV2
        title={
          <Typography fontSize="12" lineHeight={1}>
            All
          </Typography>
        }
        active={filter === ''}
        onClick={() => onSetFilter('')}
      />

      {connectionsListPresent && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-arrows-left-right mr-1.5`} />
              Network
            </Typography>
          }
          active={filter === 'Network'}
          onClick={() => onSetFilter('Network')}
        />
      )}

      {uniqueListOptions.map((option, idx) => {
        const { title, icon } = getOptionData(option)
        return (
          <PillV2
            key={idx}
            title={
              <Typography fontSize="12" lineHeight={1}>
                {icon && <i className={`far fa-${icon} mr-1.5`}></i>}
                {title}
              </Typography>
            }
            active={filter === option}
            onClick={() => onSetFilter(option)}
          />
        )
      })}

      {canViewPortfolio && team?.show_old_portfolio_on_page && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-folder-open mr-1.5`} />
              Portfolio
            </Typography>
          }
          active={filter === 'Portfolio'}
          onClick={() => onSetFilter('Portfolio')}
        />
      )}

      {canViewOffers && (
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              <i className={`far fa-toolbox mr-1.5`} />
              Resources
            </Typography>
          }
          active={filter === 'Resources'}
          onClick={() => onSetFilter('Resources')}
        />
      )}
    </div>
  )
}

export default ListPills

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import Expand from 'react-expand-animated'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useSetState } from 'react-use'

import useCrmStatus from 'components/CrmStatus'
import ListSelectorV2 from 'components/EditList/ListSelectorV2'
import { Options } from 'components/EditList/ListTypeSelector'
import FacilitatorSection from 'components/EditList/Portfolio/FacilitatorSection'
import HorizontalLine from 'components/HorizontalLine'
import CabalButton, { CabalButtonProps } from 'global/CabalButton'
import { CheckBox, MultiSelect, Select, TextInput } from 'global/Input'
import Loading from 'global/Loading'
import Modal, { ModalableComponentProps } from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper, ModalSectionWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useAdvisors, useCurrentUser, useTeam } from 'store/hooks'
import InfoTooltip from 'ui-components/InfoTooltip'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint, UserBlueprint } from 'utils/types'

import { EditListType } from '..'
import DynamicListFilters from './DynamicListFilters'
import MailListItems from './MailListItems'
import ManualListItems from './ManualListItems'
import OtherListItems from './OtherListItems'

const listParams = (list: Partial<EditListType>) => {
  return pick(list, [
    'uuid',
    'name',
    'company_list_items',
    'dynamic',
    'user',
    'list_type',
    'list_category',
    'list_subscriptions',
    'filters',
    'company_list_filters',
    'email_template_uuid',
    'provider',
    'icps',
    'webhook_url',
    'display_mode',
    'tags',
    'description',
    'is_talent',
    'cta_type',
    'admins_and_members',
    'enable_gatekeeper',
    'gatekeeper_id',
    'dynamic_gatekeeper',
    'talent_facilitator_uuid',
    'talent_approval_required',
    'sales_facilitator_uuid',
    'sales_approval_required',
    'resources_facilitator_uuid',
    'resources_approval_required',
    'sales_enable_routing',
    'talent_enable_routing',
  ])
}

interface Props {
  teamSlug: string
  listUuid?: string
  dynamic?: boolean
  provider?: CompanyListBlueprint['provider']
  reload?: () => void
  listType?: CompanyListBlueprint['list_type']
  listCategory?: string
  listItems?: string[]
  modal?: boolean
  postCrmInstallReturnTo?: string
  defaultListName?: string
  defaultListDescription?: string
  defaultListTags?: string[]
  defaultIsTalent?: boolean
  hideListName?: boolean
  hideListDescription?: boolean
  hideOfferNotification?: boolean
  hideListType?: boolean
  hideListItemsLabel?: boolean
  hideTargetPersonas?: boolean
  hideAdvance?: boolean
  hideDefaultRequestor?: boolean
  onDelete?: () => void
  renderSubmitButton?: (props: CabalButtonProps) => React.ReactNode
  ctaType?: CompanyListBlueprint['cta_type']
  enableGatekeeper?: boolean
  gatekeeper_id?: number
  dynamicGatekeeper?: boolean
  isPortfolioList?: boolean
  currentStep?: number
}

const EditListDetails: React.FC<Props & ModalableComponentProps<CompanyListBlueprint>> = ({
  teamSlug,
  listUuid,
  onSubmit,
  dynamic,
  provider,
  reload,
  listType,
  listCategory,
  listItems,
  modal = true,
  postCrmInstallReturnTo,
  resolve,
  defaultListName,
  defaultListDescription,
  defaultListTags,
  defaultIsTalent,
  hideListName,
  hideListDescription,
  hideTemplate,
  hideListType,
  hideOfferNotification,
  hideTargetPersonas,
  hideListItemsLabel,
  hideAdvance,
  renderSubmitButton,
  onDelete,
  ctaType,
  enableGatekeeper,
  hideDefaultRequestor,
  gatekeeper_id,
  dynamicGatekeeper,
  currentStep,
  isPortfolioList,
}) => {
  const [list, setList] = useSetState<EditListType>(undefined)
  const { user: currentUser } = useCurrentUser()
  const { salesforceConnected, hubspotConnected } = useCrmStatus(teamSlug)
  const { advisors } = useAdvisors({ teamSlug })
  const { team } = useTeam(teamSlug)
  const currentUserAdvisor = useMemo(
    () => advisors?.find((a) => a.user_uuid === currentUser.uuid),
    [advisors],
  )
  const creating = !listUuid
  const [showFacilitators, setShowFacilitators] = useState(false)
  const [advancedOpened, setAdvancedOpened] = useState(false)

  const handleShowFacilitators = () => {
    setShowFacilitators(!showFacilitators)
    setAdvancedOpened(!advancedOpened)
  }

  const showSelectionStep = creating && listType === undefined

  const [step, setStep] = useState(currentStep ? currentStep : showSelectionStep ? 0 : 1)

  postCrmInstallReturnTo ||= `/${teamSlug}/lists?from_salesforce=1`
  const teamDefaultICPs = team?.ideal_customer_profile
    ? team?.ideal_customer_profile?.split(',')
    : []
  const isCompanyList = list.list_type == 'companies'
  const listTypeData = window.LIST_TYPES[list.list_type]

  const getListQuery = useQuery(
    ['getList', listUuid],
    () => callApi(api.getCompanyList, teamSlug, listUuid!, false, true),
    {
      onSuccess: ({ company_list }) => {
        setList(company_list)
      },
      enabled: !creating,
    },
  )

  const persistedList = getListQuery.data?.company_list
  const isLoadingList = getListQuery.isLoading
  const persistedName = persistedList?.name

  const getEmailTemplatesQuery = useQuery(['getTemplates', teamSlug], () =>
    callApi(api.getTemplates, teamSlug, true),
  )
  const emailTemplates = getEmailTemplatesQuery.data?.templates || []

  const successToast = (action: string) => {
    cabalToast({ style: 'success', content: `List ${action} successfully!` })
  }

  const createListMutation = useMutation(
    ['createList', teamSlug],
    () =>
      callApi(api.addCompanyList, teamSlug, {
        company_list: listParams(list),
      }),
    {
      onSuccess: ({ company_list }) => {
        resolve?.()
        onSubmit?.(company_list)
        reload?.()
        successToast('created')
      },
    },
  )

  const updateListMutation = useMutation(
    ['updateList', teamSlug],
    () =>
      callApi(api.updateCompanyList, teamSlug, {
        company_list: listParams(list),
      }),
    {
      onSuccess: ({ company_list }) => {
        resolve?.()
        onSubmit?.(company_list)
        reload?.()
        successToast('updated')
      },
    },
  )

  const deleteCompanyListMutation = useMutation(
    ['deleteCompanyList', listUuid],
    () => callApi(api.deleteCompanyList, teamSlug, listUuid!),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'List deleted' })
        onDelete?.()
        resolve?.()
      },
    },
  )

  const onSave = useCallback(() => {
    if (!creating) {
      if (!isEqual(listParams(persistedList!), listParams(list))) {
        updateListMutation.mutate()
      } else {
        resolve?.()
        onSubmit?.(persistedList!)
      }
    } else {
      createListMutation.mutate()
    }
  }, [creating, list])

  useEffect(() => {
    if (creating && !!currentUserAdvisor) {
      setList({ list_subscriptions: [currentUserAdvisor.uuid] })
    }
  }, [currentUserAdvisor])

  const getDefaultFacilitator = (
    listCategory: string,
    gatekeeperId: number | undefined,
    teamFounderIdentifier: string | undefined,
  ) => {
    if (listCategory === 'talent' || listCategory === 'resources') {
      return gatekeeperId || 'dynamic_gatekeeper' || teamFounderIdentifier
    }
    return gatekeeperId || teamFounderIdentifier
  }

  const defaultFacilitator = getDefaultFacilitator(
    list.list_category,
    gatekeeper_id,
    team?.founder?.identifier,
  )

  useEffect(() => {
    if (list.dynamic_gatekeeper === undefined) {
      const defaultDynamicGatekeeper = list.gatekeeper_id === 'dynamic_gatekeeper' ? true : false
      setList({
        ...list,
        dynamic_gatekeeper: defaultDynamicGatekeeper,
      })
    }
  }, [list.gatekeeper_id])

  useEffect(() => {
    if (creating) {
      setList({
        name: defaultListName || '',
        description: defaultListDescription || '',
        company_list_items: listItems || [],
        email_template_uuid:
          list.email_template_uuid ||
          emailTemplates.find((t) => t.default_for === 'member_intro_template')?.uuid,
        user: currentUser as any as UserBlueprint,
        list_type: listType || list.list_type,
        list_category: listCategory || list.list_category,
        cta_type: list.cta_type || ctaType || 'view',
        dynamic: dynamic === undefined ? salesforceConnected || hubspotConnected : dynamic,
        list_subscriptions: currentUserAdvisor ? [currentUserAdvisor.uuid] : [],
        company_list_filters: [],
        display_mode: list.display_mode || 'list',
        webhook_url: undefined,
        provider:
          dynamic === true
            ? provider || (salesforceConnected ? 'salesforce' : hubspotConnected ? 'hubspot' : null)
            : null,
        filters: {
          owner_emails: [],
          stage_names: [],
          pipeline_names: [],
        },
        icps: isCompanyList ? teamDefaultICPs : [],
        tags: defaultListTags || [],
        is_talent: defaultIsTalent || list.is_talent || false,
        gatekeeper_id: defaultFacilitator,
        dynamic_gatekeeper: dynamicGatekeeper,
        sales_facilitator_uuid: list.sales_facilitator?.user_id,
        sales_approval_required: list.sales_facilitator?.required_approval,
        sales_enable_routing: list.sales_facilitator?.enable_routing,
        talent_facilitator_uuid: list.talent_facilitator?.user_id,
        talent_approval_required: list.talent_facilitator?.required_approval,
        talent_enable_routing: list.talent_facilitator?.enable_routing,
        resources_facilitator_uuid: list.resources_facilitator?.user_id,
        resource_approval_required: list.resource_facilitator?.required_approval,
      })
    }
  }, [defaultFacilitator])

  // const { data: requestIntroOptions, isLoading: requestIntroFetchLoading } = useQuery(
  //   ['companyList', 'requestIntroOptions'],
  //   () => callApi(api.getRequestIntroOptions, teamSlug),
  // )

  // const ctaTypeOptions = requestIntroOptions?.cta_types

  // const ctaTypeOptionsValues = Object.keys(ctaTypeOptions || {}).map((option: string) => ({
  //   label: ctaTypeOptions[option],
  //   value: option,
  // }))

  // All the conditions
  const requestIntroListType =
    list.list_type === 'companies' ||
    list.list_type === 'people' ||
    list.portfolio ||
    list.list_type === 'jobs'
  // TODO: For Sales and Fundraise
  const showActionNotifications =
    list.list_category === 'sales' || list.list_category === 'fundraise'
  const showDefaultRequestor =
    !hideDefaultRequestor && (list.list_category === 'sales' || list.list_category === 'fundraise')
  const showDefaultFacilitator =
    list.list_category === 'talent' || list.list_category === 'resources' || list.portfolio
  const hideActionNotifications = list?.cta_type == 'request_intro'

  const getDynamicGatekeeper = () => {
    if (list.list_category === 'talent') {
      return [
        {
          label: 'Candidate Owner',
          value: 'dynamic_gatekeeper',
        },
      ]
    } else if (list.list_category === 'resources') {
      return [
        {
          label: 'Portfolio Company Owner',
          value: 'dynamic_gatekeeper',
        },
      ]
    } else {
      return []
    }
  }

  const adminAndMembersValues = [
    ...getDynamicGatekeeper(),
    ...(team?.admins_and_members?.map((user) => ({
      label: `${user.first_name} ` + `${user.last_name} ` + `(${user.email})`,
      value: user?.identifier,
    })) || []),
  ]

  const baseSubscriberOptions = useMemo(() => {
    if (salesforceConnected) {
      return [
        {
          label: 'Account Owner',
          value: 'account_owner',
        },
        {
          label: 'Opportunity Owner',
          value: 'opportunity_owner',
        },
      ]
    } else if (hubspotConnected) {
      return [
        {
          label: 'Company Owner',
          value: 'account_owner',
        },
        {
          label: 'Deal Owner',
          value: 'opportunity_owner',
        },
      ]
    } else {
      return []
    }
  }, [salesforceConnected, hubspotConnected])

  const subscriberOptions = useMemo(() => {
    return (
      ['companies', 'fundraise'].includes(list.list_type) ? baseSubscriberOptions : []
    ).concat(
      advisors?.map((a) => ({
        label: a.name,
        value: a.uuid,
      })) || [],
    )
  }, [advisors, baseSubscriberOptions, list.list_type])

  if (!list || isEmpty(list) || isLoadingList) {
    if (modal) {
      return (
        <Modal header="" show onHide={resolve!}>
          <Loading className="my-8" />
        </Modal>
      )
    } else {
      return <Loading className="my-8" />
    }
  }

  const submitButtonProps = {
    onClick: onSave,
    working: createListMutation.isLoading || updateListMutation.isLoading,
    disabled: !list.name,
  }

  const submitButton = renderSubmitButton?.(submitButtonProps) || (
    <CabalButton {...submitButtonProps} data-testid="done-button">
      Done
    </CabalButton>
  )

  const getListTypeOptions = () => {
    const options = []

    if (['sales', 'fundraise', 'resources'].includes(list.list_category)) {
      options.push({ label: 'Companies', value: 'companies' })
    }

    if (['talent', 'fundraise', 'resources'].includes(list.list_category)) {
      options.push({ label: 'People', value: 'people' })
    }

    if (['talent'].includes(list.list_category) && team?.features?.new_jobs_list) {
      options.push({ label: 'Jobs', value: 'jobs' })
    }

    if (['sales'].includes(list.list_category)) {
      options.push({ label: 'Personas', value: 'personas' })
    }

    if (['resources'].includes(list.list_category)) {
      if (!creating) {
        options.push({ label: 'Mail', value: 'mail' })
      }
      options.push({ label: 'Discount', value: 'discounts' })
      options.push({ label: 'Other', value: 'other' })
    }

    if (['sales', 'fundraise'].includes(list.list_category)) {
      if (salesforceConnected) {
        options.push({ label: 'Salesforce', value: 'salesforce' })
      }
      if (hubspotConnected) {
        options.push({ label: 'Hubspot', value: 'hubspot' })
      }
    }

    return options
  }

  const child = (
    <ModalSectionWrapper>
      {!hideListName && (
        <ModalInputWrapper>
          <ModalInputLabel>Name</ModalInputLabel>
          <TextInput
            placeholder="Add a list name"
            value={list.name}
            autoFocus={true}
            onChange={(e) => setList({ name: e.currentTarget.value })}
            data-testid="list-name-input"
          />
        </ModalInputWrapper>
      )}

      {/* {!hideListDescription && (
        <ModalInputWrapper>
          <ModalInputLabel>Description</ModalInputLabel>
          <TextArea
            placeholder="Add more context for users"
            value={list.description}
            onChange={(e) => setList({ description: e.currentTarget.value })}
            data-testid="list-description-input"
            maxLength={85}
            cols={2}
          />
        </ModalInputWrapper>
      )} */}

      {!hideListType && !isPortfolioList && (
        <ModalInputWrapper>
          <ModalInputLabel>List Type</ModalInputLabel>
          <TextInput
            value={list?.list_category?.charAt(0).toUpperCase() + list?.list_category?.slice(1)}
            disabled
          />
        </ModalInputWrapper>
      )}

      {!isPortfolioList && (
        <ModalInputWrapper>
          <ModalInputLabel>List Item Type</ModalInputLabel>
          <Select
            options={getListTypeOptions()}
            noMinWidth
            className="w-full"
            value={list.dynamic === true && list.provider ? list.provider : list.list_type}
            disabled={list.list_type === 'talent' || list.list_type === 'mail' || !creating}
            onChange={(v) => {
              if (v) {
                if (v === 'salesforce' || v === 'hubspot') {
                  setList({ list_type: 'companies', dynamic: true, provider: v })
                } else if (v === 'jobs') {
                  setList({ ...list, list_type: 'jobs', display_mode: 'list' })
                } else {
                  setList({
                    list_type: v as EditListType['list_type'],
                    dynamic: false,
                    provider: undefined,
                  })
                }
              }
            }}
          />
        </ModalInputWrapper>
      )}

      {/* Begin Extra inputs for Portfolio List Type */}
      {isPortfolioList && (
        <>
          <FacilitatorSection
            title="Default Intro"
            adminAndMembersValues={adminAndMembersValues}
            selectedValue={(selectedValues: any) => {
              setList({
                ...list,
                resources_facilitator_uuid: selectedValues.selectedFacilitator,
                resources_approval_required: selectedValues.approvalRequired,
              })
            }}
            facilitatorType="resources"
            defaultOptions={[
              { label: 'Candidate Owner', value: 'candidate_owner' },
              { label: 'Portfolio Company Owner', value: 'portfolio_company_owner' },
            ]}
            initializeOptions={{
              selectedUser: list?.resources_facilitator_uuid || list.resources_facilitator.user_id,
              approvalRequired: list.resources_facilitator.required_approval,
            }}
            routingOptions={{
              enabled: false,
            }}
          />
          <CabalButton
            variant={'link'}
            lineHeight={1.2}
            fontSize="13"
            className="-ml-4"
            onClick={() => handleShowFacilitators()}
          >
            Advanced{' '}
            {advancedOpened ? (
              <i className="far fa-chevron-down fa-xs ml-2" />
            ) : (
              <i className="far fa-chevron-right fa-xs ml-2" />
            )}
          </CabalButton>
          <br />
          {showFacilitators && (
            <>
              {console.log('list:::::: ', list)}
              <FacilitatorSection
                title="Talent"
                adminAndMembersValues={adminAndMembersValues}
                selectedValue={(selectedValues: any) => {
                  setList({
                    ...list,
                    talent_facilitator_uuid: selectedValues.selectedFacilitator,
                    talent_approval_required: selectedValues.approvalRequired,
                    talent_enable_routing: selectedValues.enableRouting,
                  })
                }}
                facilitatorType="talent"
                defaultOptions={[
                  { label: 'Candidate Owner', value: 'candidate_owner' },
                  { label: 'Portfolio Company Owner', value: 'portfolio_company_owner' },
                ]}
                initializeOptions={{
                  selectedUser: list.talent_facilitator_uuid || list.talent_facilitator.user_id,
                  approvalRequired: list.talent_facilitator.required_approval,
                  enableRouting: list.talent_facilitator.routing,
                }}
                routingOptions={{
                  enabled: true,
                  text: 'If disabled, all talent intro requests will follow the default routing settings above.',
                }}
              />
              <HorizontalLine />
              <FacilitatorSection
                title="Sales"
                adminAndMembersValues={adminAndMembersValues}
                selectedValue={(selectedValues: any) => {
                  setList({
                    ...list,
                    sales_facilitator_uuid: selectedValues.selectedFacilitator,
                    sales_approval_required: selectedValues.approvalRequired,
                    sales_enable_routing: selectedValues.enableRouting,
                  })
                  console.log('List::: ', list)
                  console.log('selectedValues::: ', selectedValues)
                }}
                facilitatorType="sales"
                defaultOptions={[
                  { label: 'Portfolio Company Owner', value: 'portfolio_company_owner' },
                ]}
                initializeOptions={{
                  selectedUser: list?.sales_facilitator_uuid || list.sales_facilitator.user_id,
                  approvalRequired: list.sales_facilitator.required_approval,
                  enableRouting: list.sales_facilitator.routing,
                }}
                routingOptions={{
                  enabled: true,
                  text: 'If disabled, all sales intro requests will follow the default routing settings above.',
                }}
              />
            </>
          )}
        </>
      )}
      {/* End Extra inputs for Portfolio List Type  */}

      {/* {!isPortfolioList && (
        <ModalInputWrapper>
          <ModalInputLabel>Tag</ModalInputLabel>
          <MultiSelect
            data-testid="list-tags-select"
            options={
              TagOptions.map((t) => ({
                label: t.title,
                value: t.title,
              })) || []
            }
            value={list.tags || []}
            onChange={(v) => v && setList({ tags: v })}
            placeholder="Add tags to organize your lists"
          />
        </ModalInputWrapper>
      )} */}

      {showDefaultRequestor && (
        <ModalInputWrapper>
          <ModalInputLabel>Default Requestor</ModalInputLabel>
          <Select
            value={list.dynamic_gatekeeper ? 'dynamic_gatekeeper' : list.gatekeeper_id}
            options={adminAndMembersValues}
            onChange={(v) => {
              if (v === 'dynamic_gatekeeper') {
                setList({ dynamic_gatekeeper: true, gatekeeper_id: undefined })
              } else {
                setList({ dynamic_gatekeeper: false, gatekeeper_id: v })
              }
            }}
          />
        </ModalInputWrapper>
      )}

      {list.list_type != '' && (
        <>
          <Expand open={true} className="-my-6">
            {list.list_type === 'other' && (
              <div className="space-y-5 pb-5">
                <ModalInputWrapper>
                  <ModalInputLabel>
                    Display Mode
                    <InfoTooltip className="ml-2">
                      <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                        Choose to view these items on Home horizontally or vertically
                      </Typography>
                    </InfoTooltip>
                  </ModalInputLabel>
                  <div className="flex flex-wrap gap-2">
                    <Options
                      title={'List'}
                      icon={<i className="far fa-list"></i>}
                      onClick={() => setList({ display_mode: 'list' })}
                      selected={list.display_mode === 'list' || !list.display_mode}
                    />
                    <Options
                      title={'Grid'}
                      icon={<i className="far fa-grid"></i>}
                      onClick={() => setList({ display_mode: 'grid' })}
                      selected={list.display_mode === 'grid'}
                    />
                  </div>
                </ModalInputWrapper>
              </div>
            )}
            {list.list_type !== 'other' && !isPortfolioList && (
              <div className="space-y-5">
                {hideActionNotifications && requestIntroListType && (
                  <ModalInputWrapper>
                    <ModalInputLabel>
                      Require Facilitator Approval
                      <InfoTooltip className="ml-2">
                        <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                          If checked, intro requests are first sent to the Facilitator for review.
                          <br />
                          If unchecked, intro requests will be sent directly to the requested person
                          or company.
                        </Typography>
                      </InfoTooltip>
                    </ModalInputLabel>

                    <CheckBox
                      checked={list.enable_gatekeeper}
                      onChange={(e) => setList({ ...list, enable_gatekeeper: e.target.checked })}
                    />
                  </ModalInputWrapper>
                )}

                {showDefaultFacilitator && (
                  <ModalInputWrapper>
                    <ModalInputLabel>Default Facilitator</ModalInputLabel>
                    <Select
                      value={list.dynamic_gatekeeper ? 'dynamic_gatekeeper' : list.gatekeeper_id}
                      options={adminAndMembersValues}
                      onChange={(v) => {
                        if (v === 'dynamic_gatekeeper') {
                          setList({ dynamic_gatekeeper: true, gatekeeper_id: undefined })
                        } else {
                          setList({ dynamic_gatekeeper: false, gatekeeper_id: v })
                        }
                      }}
                    />
                  </ModalInputWrapper>
                )}

                {!hideOfferNotification && !hideActionNotifications && (
                  <ModalInputWrapper>
                    <ModalInputLabel>
                      Notifications
                      <InfoTooltip className="ml-2">
                        <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                          People who will receive notifications for Offers from this List.
                        </Typography>
                      </InfoTooltip>
                    </ModalInputLabel>
                    <MultiSelect
                      options={subscriberOptions}
                      value={list.list_subscriptions || []}
                      onChange={(v) => setList({ list_subscriptions: v })}
                      placeholder="Add people and groups to notify"
                      data-testid="share-list-notifications-multi-select"
                    />
                  </ModalInputWrapper>
                )}
                {!hideTemplate && showActionNotifications && (
                  <ModalInputWrapper>
                    <ModalInputLabel>
                      Intro Template
                      <InfoTooltip className="ml-2">
                        <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                          Email template for your members to send to prospects.
                          <div className="h-2" />
                          Emails can be customized by you or your members before sending.
                          <div className="h-1" />
                          <Typography color="fog_rain" fontSize="10">
                            Note: Only public templates can be used here.{' '}
                            <Link to={`/messages/templates`} className="underline" target="_blank">
                              Manage Templates
                            </Link>
                          </Typography>
                        </Typography>
                      </InfoTooltip>
                    </ModalInputLabel>
                    <Select
                      value={
                        list.email_template_uuid ||
                        emailTemplates.find((t) => t.default_for === 'member_intro_template')?.uuid
                      }
                      options={emailTemplates.map((t) => ({ label: t.name, value: t.uuid }))}
                      isLoading={getEmailTemplatesQuery.isLoading}
                      onChange={(v) => setList({ email_template_uuid: v })}
                    />
                  </ModalInputWrapper>
                )}
                {!hideTargetPersonas && isCompanyList && !list.portfolio && (
                  <ModalInputWrapper>
                    <ModalInputLabel>
                      Target Personas
                      <InfoTooltip className="ml-2">
                        <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
                          Companies & people matching the target personas will be ranked higher.
                        </Typography>
                      </InfoTooltip>
                    </ModalInputLabel>
                    <MultiSelect
                      isClearable
                      placeholder="Add target personas like founder, advisor etc"
                      creatable
                      compactPlaceholder
                      formatCreateLabel={() => 'Add persona'}
                      options={teamDefaultICPs.map((icp) => ({ label: icp, value: icp })) || []}
                      value={list.icps}
                      onChange={(v) => setList({ icps: v })}
                    />
                  </ModalInputWrapper>
                )}
              </div>
            )}
          </Expand>
        </>
      )}
    </ModalSectionWrapper>
  )

  const finalStep = (
    <ModalSectionWrapper>
      {!list.dynamic && list.list_type === 'other' && (
        <OtherListItems list={list} setList={setList} />
      )}
      {list.list_type == 'mail' && (
        <MailListItems
          teamSlug={teamSlug}
          hideListItemsLabel={hideListItemsLabel}
          list={list}
          setList={setList}
        />
      )}
      {!list.dynamic && list.list_type !== 'other' && list.list_type !== 'mail' && (
        <ManualListItems hideListItemsLabel={hideListItemsLabel} list={list} setList={setList} />
      )}
      {list.dynamic && <DynamicListFilters teamSlug={teamSlug} list={list} setList={setList} />}
    </ModalSectionWrapper>
  )

  const getRightAction = () => {
    if (step === 0) {
      return undefined
    } else if (step === 1 && listTypeData?.has_step_2 && !isPortfolioList) {
      return (
        <CabalButton
          disabled={!list.name || list.list_type === ''}
          onClick={() => setStep(step + 1)}
          data-testid="next-button"
        >
          Next
        </CabalButton>
      )
    } else {
      return submitButton
    }
  }

  const contents = (
    <>
      {step === 0 && (
        <ListSelectorV2
          teamSlug={teamSlug}
          onSetList={setList}
          setNextStep={() => setStep(step + 1)}
        />
      )}
      {step === 1 && child}
      {step === 2 && finalStep}
    </>
  )

  if (!modal) {
    return (
      <>
        {contents}
        <div className="flex justify-end">{getRightAction()}</div>
      </>
    )
  }

  const showDeleteButton =
    !creating && !list?.is_all_candidates && !list?.is_all_jobs && !list.portfolio

  return (
    <Modal
      dangerouslyBypassFocusLock={true}
      header={
        step != 0 && (
          <div className="flex justify-between self-center items-center py-1">
            <div className="space-x-2">
              {((step !== 0 && creating) || step === 2) && (
                <CabalButton padding="0px" variant="link" onClick={() => setStep(step - 1)}>
                  <i className="far fa-arrow-left mr-1"></i>
                </CabalButton>
              )}
              <Typography fontSize="12" fontWeight={600} lineHeight="15px">
                {creating ? 'Create List' : `Edit '${persistedName}'`}
              </Typography>
            </div>
          </div>
        )
      }
      show
      onHide={resolve!}
      rightActions={getRightAction()}
      leftActions={
        showDeleteButton && (
          <CabalButton
            variant="destructive"
            onClick={() => confirm('Are you sure?') && deleteCompanyListMutation.mutate()}
            working={deleteCompanyListMutation.isLoading}
          >
            Delete
          </CabalButton>
        )
      }
    >
      {contents}
    </Modal>
  )
}

export default EditListDetails

import React from 'react'

import { Value } from 'classnames'

import { EditListType } from 'components/EditList'
import { CheckBox, Select, SelectOption, TextInput } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import InfoTooltip from 'ui-components/InfoTooltip'

interface Props {
  list: Partial<EditListType>
  setList: (list: Partial<EditListType>) => void
  adminAndMembersValues: SelectOption<Value>[]
}

const ResourcePeopleOptions = ({ list, setList, adminAndMembersValues }: Props) => {
  return (
    <>
      <ModalInputWrapper>
        <ModalInputLabel>Name</ModalInputLabel>
        <TextInput
          placeholder="Add a list name"
          value={list.name}
          autoFocus={true}
          onChange={(e) => setList({ ...list, name: e.currentTarget.value })}
          data-testid="list-name-input"
        />
      </ModalInputWrapper>

      <ModalInputWrapper>
        <ModalInputLabel>Default Facilitator</ModalInputLabel>
        <Select
          value={list.dynamic_gatekeeper ? 'dynamic_gatekeeper' : list.gatekeeper_id}
          options={adminAndMembersValues}
          onChange={(v) => {
            if (v === 'dynamic_gatekeeper') {
              setList({ ...list, dynamic_gatekeeper: true, gatekeeper_id: undefined })
            } else {
              v && setList({ ...list, dynamic_gatekeeper: false, gatekeeper_id: Number(v) })
            }
          }}
        />
      </ModalInputWrapper>

      <ModalInputWrapper>
        <ModalInputLabel>
          Require Facilitator Approval
          <InfoTooltip className="ml-2">
            <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
              If checked, intro requests are first sent to the Facilitator for review.
              <br />
              If unchecked, intro requests will be sent directly to the requested person or company.
            </Typography>
          </InfoTooltip>
        </ModalInputLabel>

        <CheckBox
          checked={list.enable_gatekeeper}
          onChange={(e) => setList({ ...list, enable_gatekeeper: e.target.checked })}
        />
      </ModalInputWrapper>
    </>
  )
}

export default ResourcePeopleOptions
